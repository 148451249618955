import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AppLayout } from "components/index";
import {
  AIDefinitionModal,
  DecisionDetailHeader,
} from "scenes/DecisionMatrix/components";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Input, Select, Skeleton, Space } from "antd";
import { ReactComponent as OptionAvatar } from "./Optionavatar.svg";
import { ReactComponent as OptionAvatarOne } from "./optionAvatar1.svg";
import { ReactComponent as Winner } from "./winner.svg";
import { ReactComponent as DownArrow } from "./Custom-Down.svg";

import { ReactComponent as Scale } from "./Scale.svg";
import debounce from "lodash.debounce";

import { Link, useLocation, useParams } from "react-router-dom";
// import CriteriaOption from "./CriteriaComparisonLeft";
import CriteriaComparisonLeft from "./CriteriaComparisonLeft";
import { Collapse } from "antd";
import "./override.scss";
import { Popover } from "antd";
import CompareOptionHeader from "scenes/DecisionMatrix/components/CompareOptionHeader";
import {
  GetDecisionDetails,
  SetDecisionDetails,
} from "redux/DecisionMatrix/GetDecisionDetails/action";
import {
  SetSelectedOption1,
  SetSelectedOptions,
} from "redux/DecisionMatrix/SelectedOption1/action";
import { SetSelectedOption2 } from "redux/DecisionMatrix/SelectedOption2/action";

import { AIVerdictModal } from "scenes/DecisionMatrix/components/AIAssistantModal/AIVerdictModal";
import CriteriaComparisonRight from "./CriteriaComparisonRight";
import { UpdateOption } from "redux/DecisionMatrix/UpdateOptions/action";
import { FinalizeDecision } from "scenes/DecisionMatrix";
import { WinnerModal } from "../../components";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
const OptionComparison = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { decisionId } = useParams();
  const is_sample = query.get("is_sample");

  const [isShowFinalizeDecisin, setIsShowFinalizeDecisin] = useState(false);
  const [decision, setDecision] = useState({});
  const [options, setOptions] = useState([]);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState();
  const [selectedOption2, setSelectedOption2] = useState();
  const [leftOption, setLeftOption] = useState(null);
  const [leftOptionLoading, setLeftOptionLoading] = useState(false);
  const [rightOption, setRightOption] = useState(null);
  const [activeCriterias, setActiveCriterias] = useState([]);
  const [isOpenVerdictModal, setIsOpenVerdictModal] = useState(false);
  const [criterias, setCriterias] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [isUpdateMeRight, setIsUpdateMeRight] = useState(false);
  const [isTrueRight, setIsTrueRight] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [notes, setNotes] = useState("");
  const [optionId, setOptionId] = useState();
  const [rightNotes, setRightNotes] = useState("");
  const [print, setPrint] = useState(false);
  const [isShowWinner, setIsShowWinner] = useState(false);

  // const [leftOptionCriteria, setLeftOptionCriteria] = useState();
  // const [rightOptionCriteria, setRightOptionCriteria] = useState();

  const { success: userDecision } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );

  /* const { loading, leftLoading, rightLoading } = useSelector(
    (state) => state.updateOptionCriteriaReducer
  ); */
  // const [isLoading, setIsLoading] = useState(false);

  const leftLoading = false,
    rightLoading = false;

  useEffect(() => {
    if (!decisionId) return;
    dispatch(GetDecisionDetails(decisionId, true, is_sample));
    return () => {
      dispatch(SetDecisionDetails(null));
    };
  }, [decisionId]);

  const onDefinitionApplyModal = (criteria, description) => {
    setCriterias(
      criterias?.map((c) =>
        c?.id === criteria?.id ? { ...c, description } : c
      )
    );
    setSelectedCriteria(null);
    setIsOpenVerdictModal(false);
  };

  // const handleChange = (value, option) => {
  //   if (option === "option1") {
  //     const selectedOption = optionsList.find((item) => item.id === value);
  //     setSelectedOption1(selectedOption);
  //     // handleOption1();
  //   } else {
  //     const selectedOption = optionsList.find((item) => item.id === value);
  //     setSelectedOption2(selectedOption);
  //     // handleOption2();
  //   }
  // };

  const handleChange = (value, option) => {
    if (option === "option1") {
      const selectedOption = optionsList.find((item) => item.id === value);
      setSelectedOption1(selectedOption);
      dispatch(SetSelectedOption1(selectedOption));
      // Check if selectedOption1 is the same as selectedOption2
      if (
        selectedOption2 &&
        selectedOption &&
        selectedOption.id === selectedOption2.id
      ) {
        setSelectedOption2(null);
      }
    } else {
      const selectedOption = optionsList.find((item) => item.id === value);
      setSelectedOption2(selectedOption);
      dispatch(SetSelectedOption2(selectedOption));
      // Check if selectedOption2 is the same as selectedOption1
      if (
        selectedOption1 &&
        selectedOption &&
        selectedOption.id === selectedOption1.id
      ) {
        setSelectedOption1(null);
      }
    }
  };
  useEffect(() => {
    handleOption1();
  }, [selectedOption1]);

  useEffect(() => {
    if (selectedOption1 || selectedOption2) {
      setPrint(true);
    } else {
      setPrint(false);
    }
  }, [selectedOption1, selectedOption2]);

  // const handleFilterCriteria = (optionId) => {
  //   if (!userDecision?.options && !optionId) return;
  //   let optionsCopy = [...userDecision?.options];
  //   let res = optionsCopy?.filter((item) => item?.id === optionId);
  //   return res;
  // };

  // useEffect(() => {
  //   if (!userDecision) return;

  //   setLeftOptionCriteria(handleFilterCriteria(leftOption?.id));
  //   setRightOptionCriteria(handleFilterCriteria(rightOption?.id));
  // }, []);

  useEffect(() => {
    if (userDecision && userDecision?.criteria)
      setActiveCriterias(
        userDecision?.criteria
          ?.filter((c) => c.active)
          .sort((a, b) => a.id - b.id)
          .sort((a, b) => b.weight - a.weight)
      );
  }, [userDecision]);
  // useEffect(() => {
  //   const defaultValue1 =
  //     optionsList && optionsList.length > 0
  //       ? { id: optionsList[0].id, name: optionsList[0].name }
  //       : null;
  //   const defaultValue2 =
  //     optionsList && optionsList.length > 0
  //       ? { id: optionsList[1].id, name: optionsList[1].name }
  //       : null;
  //   if (optionsList?.length) {
  //     setSelectedOption1(defaultValue1);
  //     setSelectedOption2(defaultValue2);
  //     dispatch(SetSelectedOption1(defaultValue1));
  //     dispatch(SetSelectedOption2(defaultValue2));
  //   }
  // }, [optionsList]);
  const handleOption1 = () => {
    if (selectedOption1) {
      setLeftOptionLoading(true);
      const res = userDecision?.options?.find(
        (item) => item?.id === selectedOption1?.id
      );

      setLeftOption(res);
      // setNotes(res?.notes);
      setLeftOptionLoading(false);
    }
  };

  useEffect(() => {
    if (!leftOption) return;
    setNotes(leftOption?.notes);
  }, [leftOption]);

  const handleOption2 = () => {
    if (selectedOption2) {
      const res = userDecision?.options?.find(
        (item) => item?.id === selectedOption2?.id
      );

      setRightOption(res);
    }
  };
  useEffect(() => {
    if (!rightOption) return;
    setRightNotes(rightOption?.notes);
  }, [rightOption]);
  useEffect(() => {
    handleOption2();
  }, [selectedOption2, isUpdateMeRight]);

  useEffect(() => {
    if (!userDecision?.options) return;
    const options = userDecision?.options
      ?.filter((item) => item?.name)
      ?.map((obj) => {
        const { id, decision, name, adjusted_score } = obj;
        return {
          id,
          decision,
          adjusted_score,
          name: name || "N/A",
        };
      });
    setOptionsList(options);
  }, [userDecision]);
  const content = (
    <PopContainer>
      <PopText>
        <PopSpan>Adjusted</PopSpan> Score is a quantitative measure used in
        decision-making apps to factor in various criteria and weights to
        calculate a modified score for each option or alternative. It takes into
        account the importance or relevance of different factors and adjusts the
        original scores accordingly. The adjusted score provides a more
        comprehensive and balanced evaluation, enabling users to make informed
        decisions based on their specific preferences and priorities.
      </PopText>
      <PopSmallHeading>Score components</PopSmallHeading>
      <PopRow>
        <PopPersentage>21.03%</PopPersentage>
        <PopCircle color={"#89c740"}>10</PopCircle>
        <PopRowText>Customer Satisfaction</PopRowText>
      </PopRow>
      <PopRow>
        <PopPersentage>16.0%</PopPersentage>
        <PopCircle color={"#89c740"}>10</PopCircle>
        <PopRowText>Revenue Stability</PopRowText>
      </PopRow>
      <PopRow>
        <PopPersentage>42.05%</PopPersentage>
        <PopCircle color={"#feab3d"}>6</PopCircle>
        <PopRowText>Ease of Management</PopRowText>
      </PopRow>
      <PopRow>
        <PopPersentage>2.03%</PopPersentage>
        <PopCircle color={"#f00001"}>3</PopCircle>
        <PopRowText>Potential for Misuse</PopRowText>
      </PopRow>
      <PopRow>
        <PopPersentage>7.03%</PopPersentage>
        <PopCircle color={"#feab3d"}>5</PopCircle>
        <PopRowText>Complexity of Role</PopRowText>
      </PopRow>
    </PopContainer>
  );

  const onNotesChangeHandle = (event) => {
    const newNotes = event.target.value;
    setNotes(newNotes);
    updateNotes(selectedOption1, newNotes);
  };

  const updateNotes = useCallback(
    debounce((selectedOption, notes) => {
      dispatch(UpdateOption({ id: selectedOption?.id, body: { notes } }));
    }, 1000),
    []
  );

  const onNotesRightChangeHandle = (event) => {
    const newNotes = event.target.value;
    setRightNotes(newNotes); // To reflect changes immediately in the textarea
    updateNotesRight(selectedOption2, newNotes); // To update notes in the backend
  };

  const updateNotesRight = useCallback(
    debounce((selectedOption, notes) => {
      dispatch(UpdateOption({ id: selectedOption?.id, body: { notes } }));
    }, 1000),
    []
  );

  const LoadingData = () => {
    return (
      <ContentItem isSelected>
        <EditWrapper>
          <AvatarWrapper
            style={{
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></AvatarWrapper>
          <ContWrapper>
            <ContentHeading
              isLoading
              style={{
                width: "300px",
                height: "34px",
                backgroundImage:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "skeleton-animation 1.5s infinite",
              }}
            ></ContentHeading>
            <SmallTextWrapper
              isLoading
              style={{
                width: "200px",
                height: "23px",
                backgroundImage:
                  "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                backgroundSize: "200% 100%",
                animation: "skeleton-animation 1.5s infinite",
              }}
            ></SmallTextWrapper>
          </ContWrapper>
        </EditWrapper>
        <DetailHeading
          style={{
            width: "100%",
            height: "24px",
            backgroundImage:
              "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
            backgroundSize: "200% 100%",
            animation: "skeleton-animation 1.5s infinite",
          }}
        ></DetailHeading>
        <LinkHeading
          isLoading
          style={{
            width: "100%",
            height: "24px",
            backgroundImage:
              "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
            backgroundSize: "200% 100%",
            animation: "skeleton-animation 1.5s infinite",
          }}
        ></LinkHeading>
        <DetailHeading
          isLoading
          style={{
            width: "100%",
            height: "56px",
            backgroundImage:
              "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
            backgroundSize: "200% 100%",
            animation: "skeleton-animation 1.5s infinite",
          }}
        ></DetailHeading>

        <CritWrapper>
          <LargeHeading
            isLoading
            style={{
              width: "100%",
              height: "24px",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></LargeHeading>
          <Wrapper
            isLoading
            style={{
              width: "100%",
              height: "213px",
              marginTop: "1rem",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></Wrapper>
          <Wrapper
            isLoading
            style={{
              width: "100%",
              height: "213px",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></Wrapper>
          <Wrapper
            isLoading
            style={{
              width: "100%",
              height: "213px",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></Wrapper>
          <Wrapper
            isLoading
            style={{
              width: "100%",
              height: "213px",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></Wrapper>
          <Wrapper
            isLoading
            style={{
              width: "100%",
              height: "213px",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></Wrapper>
          <Wrapper
            isLoading
            style={{
              width: "100%",
              height: "213px",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></Wrapper>
          <Wrapper
            isLoading
            style={{
              width: "100%",
              height: "213px",
              backgroundImage:
                "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
              backgroundSize: "200% 100%",
              animation: "skeleton-animation 1.5s infinite",
            }}
          ></Wrapper>
        </CritWrapper>
      </ContentItem>
    );
  };

  const CustomOptionLabel = ({ index, name, adjusted_score }) => (
    <div>
      <CustomOption>{`${index}. ${name} `}</CustomOption>
      {adjusted_score !== undefined && <Span>{adjusted_score}%</Span>}
    </div>
  );

  return (
    <div>
      <div>
        <AppLayout>
          {userDecision && (
            <CompareOptionHeader
              setIsShowFinalizeDecisin={setIsShowFinalizeDecisin}
              isPrint={print}
            />
          )}
          <Container>
            {/* <LeftSide>
            <CustomTabButton isActive>Details</CustomTabButton>
            <CustomTabButton isDisable>Verdict</CustomTabButton>
            <CustomTabButton isDisable>Criteria</CustomTabButton>
          </LeftSide> */}
            <RightSide>
              {/* <LeftSide>
              <CustomTabButton isActive>Details</CustomTabButton>
              <CustomTabButton isDisable>Verdict</CustomTabButton>
              <CustomTabButton isDisable>Criteria</CustomTabButton>
            </LeftSide> */}
              <Header>
                <Options>
                  <SelecWrapper>
                    <CustomSelect
                      // value={
                      //   selectedOption1
                      //     ? `${
                      //         optionsList?.findIndex(
                      //           (obj) => obj?.id === selectedOption1?.id
                      //         ) + 1
                      //       }. ${selectedOption1?.name} ${(
                      //         <Span>{selectedOption1?.adjusted_score}</Span>
                      //       )}`
                      //     : null
                      // }
                      value={selectedOption1 ? selectedOption1.id : null}
                      placeholder="Select option" // Placeholder text
                      className="custom-select"
                      suffixIcon={<DownArrow />}
                      onChange={(value) => handleChange(value, "option1")}
                      options={[
                        { value: null, label: "Select option" }, // Placeholder option
                        ...optionsList?.map((obj, i) => ({
                          value: obj.id,
                          label: (
                            <CustomOptionLabel
                              index={i + 1}
                              name={obj.name}
                              adjusted_score={obj.adjusted_score}
                            />
                          ),
                          disabled:
                            (selectedOption1 &&
                              selectedOption1.id === obj.id) ||
                            (selectedOption2 && selectedOption2.id === obj.id),
                        })),
                      ]}
                    />
                  </SelecWrapper>
                </Options>
                <OptionHeading>VS</OptionHeading>
                <Options>
                  <SelecWrapper>
                    <CustomSelect
                      // value={
                      //   selectedOption2 ? selectedOption2.name : null // Set to null if no option is selected initially
                      // }
                      // value={
                      //   optionsList?.find(
                      //     (item) => item?.id === selectedOption2?.id
                      //   )?.name
                      // }
                      // value={
                      //   selectedOption2
                      //     ? `${
                      //         optionsList?.findIndex(
                      //           (obj) => obj?.id === selectedOption2?.id
                      //         ) + 1
                      //       }. ${selectedOption2?.name}`
                      //     : null
                      // }
                      value={selectedOption2 ? selectedOption2.id : null}
                      suffixIcon={<DownArrow />}
                      placeholder="Select option" // Placeholder text
                      // defaultValue={selectedOption2?.name}
                      onChange={(value) => handleChange(value, "option2")}
                      options={[
                        { value: null, label: "Select option" },
                        ...optionsList?.map((obj, i) => ({
                          value: obj?.id,
                          label: (
                            <CustomOptionLabel
                              index={i + 1}
                              name={obj.name}
                              adjusted_score={obj.adjusted_score}
                            />
                          ),
                          disabled:
                            (selectedOption1 &&
                              selectedOption1.id === obj?.id) ||
                            (selectedOption2 && selectedOption2.id === obj?.id),
                        })),
                      ]}
                    />
                  </SelecWrapper>
                </Options>
              </Header>

              <Content>
                {selectedOption1 == undefined ? (
                  <ContentItem>
                    <UnSelectContent>
                      <Scale />
                      <ScaleText>
                        Please select first option from dropdown menu above.
                      </ScaleText>
                    </UnSelectContent>
                  </ContentItem>
                ) : (
                  <>
                    {leftLoading ? (
                      <LoadingData />
                    ) : (
                      <ContentItem isSelected>
                        {/* jb select nai hoga tw yh dhkae ga  */}
                        {/* <UnSelectContent>
                  <Scale />
                  <ScaleText>
                    Please select second option from dropdown menu above.
                  </ScaleText>
                </UnSelectContent> */}
                        <EditWrapper>
                          <AvatarWrapper>
                            {leftOption?.image ? (
                              <AvatarImage
                                src={leftOption?.image}
                                alt="userImg"
                              />
                            ) : (
                              <AvatarImage
                                src={require("./Placeholder.jpg")}
                                alt="winner"
                              />
                            )}
                          </AvatarWrapper>
                          <ContWrapper>
                            <ContentHeading>
                              {leftOption?.name}{" "}
                              {userDecision?.final_option
                                ? userDecision?.final_option?.id ===
                                    selectedOption1?.id && <Winner />
                                : null}
                            </ContentHeading>
                            <SmallTextWrapper>
                              <SmallText>
                                Adjusted Score:{" "}
                                <TextSpan>
                                  {leftOption?.adjusted_score}%
                                </TextSpan>{" "}
                                <Popover
                                  placement="bottomLeft"
                                  content={content}
                                >
                                  <SpanCircle>&#63;</SpanCircle>
                                </Popover>
                              </SmallText>
                              <SmallText>
                                Average Score:{" "}
                                <TextSpan>
                                  {leftOption?.weighted_score}%
                                </TextSpan>{" "}
                                <Popover
                                  placement="bottomLeft"
                                  content={content}
                                >
                                  <SpanCircle>&#63;</SpanCircle>
                                </Popover>
                              </SmallText>
                            </SmallTextWrapper>
                          </ContWrapper>
                        </EditWrapper>
                        <DetailHeading>Details</DetailHeading>
                        <LinkHeading>Link</LinkHeading>
                        <Links>{leftOption?.link}</Links>
                        <DetailHeading>Notes</DetailHeading>
                        {/* <Notes>{leftOption?.notes}</Notes> */}
                        {/* <Textarea
                        value={notes}
                        placeholder="..."
                        onChange={(e) =>
                          onNotesChangeHandle(e, selectedOption1)
                        }
                      /> */}
                        <Textarea
                          value={notes}
                          placeholder="..."
                          onChange={onNotesChangeHandle}
                        />

                        {/* <Notes>{leftOption?.notes}</Notes> */}
                        {/* <Textarea
                        value={notes}
                        placeholder="..."
                        onChange={(e) =>
                          onNotesChangeHandle(e, selectedOption1)
                        }
                      /> */}

                        <CritWrapper>
                          <LargeHeading>Criteria</LargeHeading>
                          <Wrapper>
                            <CriteriaComparisonLeft
                              leftOption={leftOption}
                              rightOption={rightOption}
                              activeCriterias={activeCriterias}
                              isUpdateMe={isUpdateMeRight}
                              setIsUpdateMe={setIsUpdateMeRight}
                              isTrue={isTrueRight}
                              setIsTrue={setIsTrueRight}
                              isUpdate={handleOption1}
                              activeKey={activeKey}
                              setActiveKey={setActiveKey}
                            />
                          </Wrapper>
                        </CritWrapper>
                      </ContentItem>
                    )}
                  </>
                )}
                {selectedOption2 == undefined ? (
                  <ContentItem>
                    <UnSelectContent>
                      <Scale />
                      <ScaleText>
                        Please select second option from dropdown menu above.
                      </ScaleText>
                    </UnSelectContent>
                  </ContentItem>
                ) : (
                  <>
                    {rightLoading ? (
                      <LoadingData />
                    ) : (
                      <ContentItem isSelected>
                        <EditWrapper>
                          <AvatarWrapper>
                            {rightOption?.image ? (
                              <AvatarImage src={rightOption?.image} alt="" />
                            ) : (
                              <AvatarImage
                                src={require("./Placeholder.jpg")}
                                alt="winner"
                              />
                            )}
                          </AvatarWrapper>
                          <ContWrapper>
                            <ContentHeading>
                              {rightOption?.name}{" "}
                              {userDecision?.final_option
                                ? userDecision?.final_option?.id ===
                                    selectedOption2?.id && <Winner />
                                : null}
                            </ContentHeading>
                            <SmallTextWrapper>
                              <SmallText>
                                Adjusted Score:{" "}
                                <TextSpan>
                                  {rightOption?.adjusted_score}%
                                </TextSpan>{" "}
                                <Popover
                                  placement="bottomLeft"
                                  content={content}
                                >
                                  <SpanCircle>&#63;</SpanCircle>
                                </Popover>
                              </SmallText>
                              <SmallText>
                                Average Score:{" "}
                                <TextSpan>
                                  {rightOption?.weighted_score}%
                                </TextSpan>{" "}
                                <Popover
                                  placement="bottomLeft"
                                  content={content}
                                >
                                  <SpanCircle>&#63;</SpanCircle>
                                </Popover>
                              </SmallText>
                            </SmallTextWrapper>
                          </ContWrapper>
                        </EditWrapper>
                        <DetailHeading ismargin></DetailHeading>
                        <LinkHeading>Link</LinkHeading>
                        <Links>{rightOption?.link}</Links>
                        <DetailHeading>Notes</DetailHeading>
                        {/* <Notes>{rightOption?.notes}</Notes> */}
                        <Textarea
                          value={rightNotes}
                          placeholder="..."
                          onChange={onNotesRightChangeHandle}
                        />
                        {/* <LargeHeading>Verdict</LargeHeading>
                <VertictText>ClickMinded Digital Marketing Course</VertictText>
                <NotesText>
                  Master Digital Marketing Strategy, Social Media Marketing,
                  SEO, YouTube, Email, Facebook Marketing, Analytics &
                  More!Master Digital Marketing Strategy, Social Media
                  Marketing, SEO, YouTube, Email, Facebook Marketing, Analytics
                  & More!Master Digital Marketing Strategy, Social Media
                  Marketing, SEO, YouTube, Email, Facebook Marketing, Analytics
                  & More!Master Digital Marketing Strategy, Social Media
                  Marketing, SEO, YouTube, Email, Facebook Marketing, Analytics
                  & More!
                </NotesText> */}
                        <CritWrapper>
                          <LargeHeading>
                            {" "}
                            <HeadingSpan>
                              Scores after Second stage{" "}
                            </HeadingSpan>
                          </LargeHeading>
                          <Wrapper>
                            <CriteriaComparisonRight
                              isUpdateMe={isUpdateMeRight}
                              setIsUpdateMe={setIsUpdateMeRight}
                              isTrue={isTrueRight}
                              setIsTrue={setIsTrueRight}
                              leftOption={leftOption}
                              rightOption={rightOption}
                              activeCriterias={activeCriterias}
                              isUpdate={handleOption2}
                              activeKey={activeKey}
                              setActiveKey={setActiveKey}
                            />
                          </Wrapper>
                        </CritWrapper>
                      </ContentItem>
                    )}
                  </>
                )}
              </Content>

              {/* <CritWrapper>
              <LargeHeading>Criteria</LargeHeading>
              <Wrapper>
                <CriteriaComparison
                  leftOption={leftOption}
                  rightOption={rightOption}
                  activeCriterias={activeCriterias}
                />
              </Wrapper>
            </CritWrapper> */}
            </RightSide>
          </Container>
        </AppLayout>
        {isOpenVerdictModal && (
          <AIVerdictModal
            decisionID={decisionId}
            criteria={activeCriterias}
            handleApply={onDefinitionApplyModal}
            onClose={() => setIsOpenVerdictModal(false)}
            selectedCriteria={selectedCriteria}
            optionId={selectedOption1?.id}
            // onSelectedCriteriaChange={(name, value) => setSelectedCriteria(value)}
            onSelectedCriteriaChange={(name, value) => {
              let res = activeCriterias?.find(
                (item) => item?.id === parseInt(value)
              );
              setSelectedCriteria({ id: res?.id, title: res?.title });
            }}
          />
        )}
      </div>
      {isShowFinalizeDecisin && (
        <FinalizeDecision
          decision={decision}
          options={options}
          onClose={() => setIsShowFinalizeDecisin(false)}
          onShowWinner={() => setIsShowWinner(true)}
        />
      )}
      {isShowWinner && <WinnerModal onClose={() => setIsShowWinner(false)} />}
    </div>
  );
};

export default OptionComparison;
const Container = styled.div`
  width: calc(99% - 2rem);
  background-color: #ffff;
  height: calc(100vh - 18rem);
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
`;
const LeftSide = styled.div`
  max-width: 10%;
  display: flex;
  gap: 1rem;
  padding: 0.4rem 0.8rem;
  margin: 0.8rem 0;
  min-width: 190px;
`;
const RightSide = styled.div`
  /* border: 1px solid green; */
  width: 1080px;
  height: 100%;
  padding: 0 0.5rem;
  margin: 0.8rem 0;
  height: calc(100% - 1.6rem);
  overflow: auto;
  border-left: 1px solid #d9d9d9;
  margin-bottom: 1rem;

  ::-webkit-scrollbar {
    width: 0;
  }
`;
const GenerateAIButton = styled.button`
  color: var(--denim, #1271a6);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  background-color: transparent;
  line-height: 16px; /* 114.286% */
`;
const CustomTabButton = styled.button`
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: ${({ isActive }) =>
    `1px solid ${isActive ? "#1271a6" : "#cdcdcd"}`};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ isActive }) => ` ${isActive ? 500 : 400}`};
  line-height: 32px;
  color: ${({ isActive }) => `${isActive ? "#1271a6" : "#737373"}`};
  cursor: pointer;
  margin-top: 0.5rem;
  user-select:${({ isDisable }) => isDisable && "none"}

  &:focus {
    color: #1271a6;
    border-bottom: 2px solid #1271a6;
    font-weight: 500;
  }
`;
const Header = styled.div`
  width: 1068px;
  background-color: #1271a6;
  height: 66px;
  display: flex;
  justify-content: flex-start;
  padding: 0 1rem;
  align-items: center;
  flex-shrink: 0;
  gap: 1.5rem;
  position: fixed;
  z-index: 100;
`;
const Options = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
const OptionHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  white-space: nowrap;
`;
const SelecWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ant-select {
    border: none !important;
  }
  .ant-select-selector {
    border-color: red !important ;
    border-radius: 0px !important;
    box-shadow: none !important;
  }
`;
const CustomSelect = styled(Select)`
  width: 100%;
  overflow: hidden;

  .ant-select-selection-item {
    color: #171717;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
  }

  /* svg {
    fill: gray !important;
  } */
`;
const Span = styled.span`
  color: #3090f1;
`;
const Content = styled.div`
  position: relative;
  z-index: 1;
  top: 4.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  /* padding: 0 1.5rem; */
  align-items: flex-start;
  flex-shrink: 0;
  gap: 5rem;
`;
const ContentHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* margin-top: 1rem !important; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 350px;
  word-break: break-word;
`;
const ImageWinner = styled.img``;
const SmallTextWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

const SmallText = styled.p`
  margin: 0;
  padding: 0;
  color: #525252;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
`;
const TextSpan = styled.span`
  color: #3090f1;
  font-weight: 500;
`;
const ContentItem = styled.div`
  width: 50%;
  display: flex;

  // height: ${({ isSelected, leftLoading }) =>
    isSelected ? "100%" : `1050px`};
  justify-content: ${({ isSelected }) =>
    isSelected ? "flex-start" : "center"};
  align-items: ${({ isSelected }) => (isSelected ? "flex-start" : "center")};
  flex-direction: column;
  gap: 0.6rem;
  /* height: inherit; */
  border: ${({ isSelected }) => (isSelected ? "none" : "1px solid #E5E5E5")};
  border-radius: ${({ isSelected }) => (isSelected ? "none" : "2px")};
  margin-top: ${({ isSelected }) => (isSelected ? "0" : "1rem")};
`;
const ScaleText = styled.p`
  color: #878787;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;
const UnSelectContent = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem; */
  position: absolute;
  top: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;
const SpanCircle = styled.div`
  border: 0.4px solid #8db4ca;
  color: #8db4ca;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
`;
const EditWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
`;

const AvatarWrapper = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 4px;
`;
const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`;
const ContWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DetailHeading = styled.h2`
  margin: 0;
  margin-top: ${({ ismargin }) => ismargin && "1.32rem"};
  padding: 0;
  color: #1b2a3d;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  width: 100%;
`;
const LinkHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const Notes = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #112538;
  padding: 0.3rem;
  border: 1px solid #e3e5e9;
  border-radius: 4px;
  min-width: 100%;
  min-height: 56px;
  white-space: pre-line; /* Add this line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
`;
const NotesText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #112538;
  padding: 0.3rem;
  border: 1px solid #e3e5e9;
  border-radius: 4px;
  min-height: 105px;
  width: 100%;
  white-space: pre-line; /* Add this line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
  padding: 8px 12px 0px 12px;
`;

const LargeHeading = styled.h1`
  margin: 0;
  padding: 0;
  color: #112538;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  min-height: 24px;
  margin-bottom: 0.8rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const VertictText = styled.h2`
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: #112538;
  margin-top: 0.5rem;
  margin-bottom: 0.4rem;
  min-height: 25px;
`;
const CritWrapper = styled.div`
  margin-top: 0.6rem;
  width: 100%;
`;
const Wrapper = styled.div`
  overflow: hidden;
`;
const LoaderWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  background-color: red;
  height: 410px;
`;
const PopContainer = styled.div`
  width: 300px;
`;
const PopText = styled.p`
  margin: 0;
  padding: 0;
  color: #464646;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const PopSpan = styled.span`
  color: #000;
  font-weight: 700;
`;
const PopSmallHeading = styled.h2`
  margin: 0;
  padding: 0;
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0.4rem 0;
`;
const PopRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-top: 0.8rem;
`;
const PopPersentage = styled.h2`
  margin: 0;
  padding: 0;
  color: #000;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  width: 30px;
`;
const PopCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  padding-top: 2px;
  justify-content: center;
  align-items: center;
`;
const PopRowText = styled.p`
  margin: 0;
  padding: 0;
  color: #313131;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
const HeadingSpan = styled.p`
  margin: 0;
  padding: 0;
  margin-left: auto;
  color: #f90;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.5px;
`;
const Links = styled(Link)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: clip;
  text-overflow: ellipsis;
  line-height: 1.5;
  width: 100%;
`;
const Textarea = styled.textarea`
  color: #112538;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 8px 12px;
  min-width: 100%;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e3e5e9;
  width: 100%;
  min-height: 60px;

  &::placeholder {
    color: #e6e7eb;
  }
`;
const CustomOption = styled.span`
  display: block;
  width: 100%;
  max-width: 440px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
