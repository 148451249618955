import { forwardRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

interface ButtonProps {
  styleType?: "primary" | "secondary" | "link" | "link-primary";
  size?: "sm" | "md";
  [key: string]: any; // Add specific props as needed
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { styleType = "primary", size = "sm", disabled, children, ...rest },
    ref
  ) => {
    return (
      <ButtonStyled
        disabled={disabled}
        $type={styleType}
        $size={size}
        ref={ref}
        {...rest}
      >
        {children}
      </ButtonStyled>
    );
  }
);

const ButtonPropTypes = {
  styleType: PropTypes.oneOf(["primary", "secondary", "link", "link-primary"]),
  size: PropTypes.oneOf(["default", "medium"]),
  children: PropTypes.node,
};

Button.propTypes = ButtonPropTypes;

export const ButtonStyled = styled.button<{
  $size?: "sm" | "md";
  $type?: "primary" | "secondary" | "link" | "link-primary";
}>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-family: Inter;
  font-weight: 500;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  :disabled {
    cursor: not-allowed;
  }

  ${({ $size }) =>
    $size &&
    $size === "sm" &&
    css`
      height: 36px;
      padding: 6px 12px;
      font-size: 12px;
      line-height: 20px;
    `}

  ${({ $size }) =>
    $size &&
    $size === "md" &&
    css`
      font-size: 14px;
      padding: 10px 12px;
    `}

  ${({ $type }) =>
    $type &&
    $type === "primary" &&
    css`
      color: #fff;
      background-color: #1271a6;

      :focus {
        box-shadow: 0 0 0 0.25rem #1271a67f;
      }

      :hover {
        background-color: #116595;
      }

      :disabled {
        background-color: #71aaca;
      }
    `}

  ${({ $type }) =>
    $type &&
    $type === "secondary" &&
    css`
      color: #1271a6;
      background-color: #ecf3f6;

      :focus {
        box-shadow: 0 0 0 0.25rem #ecf3f67f;
      }

      :hover {
        background-color: #d0e3ed;
      }

      :disabled {
        background-color: #ecf3f6;
      }
    `}

  ${({ $type }) =>
    $type &&
    $type === "link" &&
    css`
      color: #ff9900;
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;

      :disabled {
        background-color: #ecf3f6;
      }
    `}

  ${({ $type }) =>
    $type &&
    $type === "link-primary" &&
    css`
      color: #1271a6;
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;

      :disabled {
        background-color: #ecf3f6;
        opacity: 0.5;
      }
    `}
`;

export default Button;
