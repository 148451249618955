import React from "react";

import { ReactComponent as DeleteSmall } from "assets/svg/deleteSmall.svg";
import { Row } from "antd";
import AppInput from "../../../../../components/AppInput";
import { updateResources } from "../../../../../modules/actions/PlanActions";
import { useDispatch } from "react-redux";

const ToolsEdit = ({
  tool,
  index,
  editTool,
  setEditTool,
  id,
  planDetails,
  handleChange,
}) => {
  const dispatch = useDispatch();

  const closeModal = () => {};
  const handleDelete = () => {
    const newTools = editTool?.filter((item) => item?.id !== tool?.id);
    setEditTool(newTools);

    // const ids = newTools?.map((item) => item?.id);
    // const payload = { tools: ids };

    // handleChange("selectedTools", newTools);
    // dispatch(
    //   updateResources(
    //     planDetails?.resource?.id,
    //     payload,
    //     id,
    //     closeModal,
    //     "Tool Deleted Successfully"
    //   )
    // );
  };

  const handleEdit = (value) => {
    const newTools = editTool?.map((item) => {
      if (item?.id === tool?.id) {
        return { ...item, name: value };
      }
      return item;
    });

    setEditTool(newTools);
  };

  return (
    <div>
      <Row
        justify="space-between"
        align={"middle"}
        style={{
          marginBottom: 8,
        }}
      >
        <div className="font-12 mb-1 text_400">Tool {index + 1} Name</div>
        <DeleteSmall className={"c-pointer"} onClick={handleDelete} />
      </Row>

      <AppInput
        value={tool?.name}
        onChange={(name, value) => handleEdit(value)}
        placeholder="Choose a collaborator"
        height={36}
        borderRadius={4}
        notPadding
      />
    </div>
  );
};

export default ToolsEdit;
