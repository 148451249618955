import React from "react";
import classes from "./EditDecisionModal.module.scss";
import { useDispatch, useSelector } from "react-redux";

import FormInput from "components/FormInput/FormInput";
import { Button, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import * as yup from "yup";
import { Formik } from "formik";
import { CreateDecision } from "redux/DecisionMatrix/AssignDecision/action";
import { UpdateDecision } from "redux/DecisionMatrix/UpdateDecision/action";

const EditDecisionModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
  decision,
  isDecisionDetail,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.updateDecisionReducer);
  const { loading: createDecisionLoading } = useSelector(
    (state) => state.createDecisionReducer
  );
  const schema = yup.object({
    // problem: yup.string().required("Enter this field"),
    problem: yup
      .string()
      .required("Enter this field")
      .max(250, "Only 250 characters are allowed")
      .matches(/.*\S.*/, "This field cannot contain only blank spaces")
      .matches(
        /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
        "Avoid special characters"
      ),
    // description: yup.string().required("Enter this field"),
    description: yup
      .string()
      .min(3, "It should be min 3 characters")
      .max(500, "Only 500 characters are allowed")
      .required("Enter this field")
      .matches(/.*\S.*/, "This field cannot contain only blank spaces")
      .matches(
        /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
        "Avoid special characters"
      ),
    priority: yup.number().required("Enter this field"),
    startline: yup.date().required("Enter this field"),
    deadline: yup.date().required("Enter this field"),
    // goal: yup.number(),
    num_criteria: !decision
      ? yup
          .number()
          .min(3, "Value must be greater than or equal to 3.")
          .typeError("The amount invalid")
          .required("Enter this field")
          .test(
            "no-leading-zero",
            "Leading zero is not allowed",
            (value, context) => {
              return (
                context.originalValue && !context.originalValue.startsWith("0")
              );
            }
          )
      : yup.number(),
  });

  const backToDecisions = () => {
    setIsEditModalOpen(false);
  };

  const onFormSubmit = async (values) => {
    if (values && !decision) {
      dispatch(CreateDecision(values, backToDecisions));
      return;
    }
    dispatch(
      UpdateDecision(
        { id: decision.id, body: { ...values, num_criteria: +3 } },
        backToDecisions,
        isDecisionDetail
      )
    );
  };
  let dtToday = new Date();

  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var minDate = year + "-" + month + "-" + day;

  return (
    <>
      <div className={classes.background} />
      <div className={classes.modal}>
        <div className={classes.wrapper}>
          <div className={classes.body}>
            <Formik
              initialValues={{
                problem: decision?.problem || "",
                description: decision?.description || "",
                startline: decision?.startline || "",
                deadline: decision?.deadline || "",
                // goal: decision?.goal || '',
                num_criteria: decision?.num_criteria || "",
                priority: decision?.priority || 1,
                // image:decision?.image || ''
              }}
              validationSchema={schema}
              validateOnChange={false}
              onSubmit={async (values) => {
                await onFormSubmit(values);
              }}
            >
              {({ handleSubmit, setFieldValue, values, errors }) => (
                <form
                  className={classes.form}
                  onClick={(event) => event.stopPropagation()}
                >
                  <div className={classes.header}>
                    <div>
                      <p className={classes.headerTitle}>
                        {!decision ? "Create Decision" : "Edit Decision"}
                      </p>
                    </div>
                    <button onClick={backToDecisions} className={classes.close}>
                      <CloseOutlined style={{ color: "white" }} />
                    </button>
                  </div>
                  <div className={classes.formWrapper}>
                    <FormInput
                      label={"Decision"}
                      name={"problem"}
                      placeholder={"What is the problem?"}
                      error={errors.problem}
                      setFieldValue={setFieldValue}
                      value={values.problem}
                      title={values.problem}
                    />

                    <FormInput
                      type="textarea"
                      label={"Description"}
                      name={"description"}
                      placeholder={"Enter your description"}
                      error={errors.description}
                      setFieldValue={setFieldValue}
                      value={values.description}
                    />

                    <FormInput
                      label={"Priority"}
                      name={"priority"}
                      error={errors.priority}
                      setFieldValue={setFieldValue}
                      value={values.priority}
                      type="select"
                      options={[
                        { value: 3, label: "High" },
                        { value: 2, label: "Medium" },
                        { value: 1, label: "Low" },
                      ]}
                    />

                    <div className={classes.inputValue}>
                      <FormInput
                        label={"Startline"}
                        name={"startline"}
                        placeholder={"YYYY-MM-DD"}
                        error={errors.startline}
                        type={"date"}
                        setFieldValue={setFieldValue}
                        value={values.startline}
                        min={minDate}
                        max={values?.deadline}
                      />

                      <FormInput
                        label={"Deadline"}
                        name={"deadline"}
                        placeholder={"YYYY-MM-DD%"}
                        error={errors.deadline}
                        type={"date"}
                        max={"100"}
                        setFieldValue={setFieldValue}
                        value={values.deadline}
                        min={values?.startline || minDate}
                      />
                    </div>

                    <div>
                      <label>Duration</label>
                      {values?.startline && values.deadline ? (
                        <p>
                          {(Date.parse(values.deadline) -
                            Date.parse(values.startline)) /
                            1000 /
                            3600 /
                            24 || 0}{" "}
                          days
                        </p>
                      ) : (
                        <p className={classes.warning}>
                          Startline & Deadline not selected
                        </p>
                      )}

                      {!decision && (
                        <FormInput
                          label={
                            "How many criteria do you want to include in the decision?"
                          }
                          name={"num_criteria"}
                          placeholder={"Enter at least 3 criteria"}
                          error={errors.num_criteria}
                          setFieldValue={setFieldValue}
                          value={
                            values.num_criteria === 0 ? "" : values.num_criteria
                          }
                          type={"number"}
                          min="3"
                        />
                      )}

                      <div className={classes.footerNavigation}>
                        <Button
                          disabled={loading || createDecisionLoading}
                          className={classes.button}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          SAVE
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export { EditDecisionModal };
