import { Row, Divider, Dropdown } from "antd";
import React, { useMemo } from "react";
import { ReactComponent as Dots } from "assets/svg/dots.svg";
import styles from "./Header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  readAllProjectNotification,
  readProjectNotification,
} from "modules/actions/UserActions";

export default function Notification({ open, handleChange }) {
  const count = 100;
  const { notifications } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRead = (notification) => {
    const payload = {
      notification,
    };
    dispatch(readProjectNotification(payload));
  };

  const handleReadAll = () => {
    dispatch(readAllProjectNotification());
  };

  const handleClick = (item) => {
    if (!item?.archive_project) {
      navigate(`/project-planner/plan-details/${item?.project_plan}/resources`);
      handleRead(item?.id);
    }
  };

  const handleMenuClick = (e, item) => {
    e.domEvent.stopPropagation();
    handleRead(item?.id);
  };

  const getMenuProps = (item) => ({
    className: "removeOption",
    items: [
      {
        key: "1",
        onClick: (e) => handleMenuClick(e, item),
        label: <span className="text_red uppercase">Mark as read</span>,
      },
    ],
  });

  const memoizedMenuProps = useMemo(
    () => notifications?.data?.map((item) => getMenuProps(item)),
    [notifications]
  );

  return (
    <div className={styles.NotificationView}>
      <Row
        justify="space-between"
        style={{ padding: 15, paddingTop: 25, zIndex: 999 }}
      >
        <div className="text_black">Notification</div>
        <Row className="d-flex">
          <div className="text_primary c-pointer" onClick={handleReadAll}>
            Mark all as read
          </div>
          {/* <Dropdown
            overlay={
              <Menu
                className="removeOption"
                subMenuCloseDelay={0.1}
                items={[
                  {
                    key: "1",
                    onClick: () => navigate("/settings"),
                    label: <span className="text_red upercase">Settings</span>,
                  },
                ]}
              />
            }
          >
            <Dots className={styles.vdots} />
          </Dropdown> */}
        </Row>
      </Row>
      <Divider style={{ marginTop: 5, marginBottom: 0 }} />
      {notifications?.data?.length > 0 &&
        notifications?.data?.map((item, index) => (
          <Row
            key={index}
            onClick={() =>
              item?.project_plan ? handleClick(item) : console.log("no plan id")
            }
            style={{ backgroundColor: !item?.read ? "#f3f3f3" : "white" }}
            className={styles.notificationCard}
            justify="space-between"
          >
            {/* <Health /> */}
            <div className="font-12 text_black width70">
              <div className="font-12 text_black text-twoline">
                {item?.title}
              </div>
              <div className="font-12 text_light_grey c-word-break">
                {item?.body?.slice(0, count) +
                  (item?.body?.length > count ? "..." : "")}
              </div>
            </div>
            <div className="flex-end">
              <div className="font-12 text_black">
                {moment(item?.created_at).fromNow()}
              </div>

              {item && !item?.read && (
                <Dropdown menu={memoizedMenuProps[index]}>
                  <div
                    className={styles.hdots_container}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Dots className={styles.hdots} />
                  </div>
                </Dropdown>
              )}
            </div>
          </Row>
        ))}
    </div>
  );
}
